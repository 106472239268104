/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";

const ContactDetails = ({ submitError }) => {
    const classes = useStyle();

    const [openContactDetails, setOpenContactDetails] = useState(false);

    const [name, setName] = useState("");
    const [phoneCode, setPhoneCode] = useState("62");
    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const [error, setError] = useState(false);

    const handleSubmit = () => {
        let KAI = JSON.parse(localStorage.getItem("KAI"));

        KAI.customerContactDetails = {}
        if (name !== "") KAI.customerContactDetails.name = name
        else KAI.customerContactDetails.name = "";

        if (phoneCode !== "") KAI.customerContactDetails.phoneCode = phoneCode
        else KAI.customerContactDetails.phoneCode = "";

        if (phoneNo !== "" && phoneNo.length >= 10 && phoneNo > 0) KAI.customerContactDetails.phoneNo = phoneNo
        else KAI.customerContactDetails.phoneNo = "";

        if (email !== "" && emailRegex.test(email)) KAI.customerContactDetails.email = email
        else KAI.customerContactDetails.email = "";

        localStorage.setItem("KAI", JSON.stringify(KAI));

        if (name !== "" && phoneCode !== "" && phoneCode > 0 && phoneNo !== "" && phoneNo.length >= 10 && phoneNo > 0 && email !== "" && emailRegex.test(email)) {
            setOpenContactDetails(false);
            // console.log("contact details filled correctly....")
        }
        else
            setError(true)

    }

    useEffect(() => {
        if (submitError) {
            if (name === "" || phoneCode === "" || phoneNo.length <= 10 || phoneNo < 0 || phoneNo === "" || email === "" || !emailRegex.test(email)) {
                setError(true);
                setOpenContactDetails(true);
            }
        }
    }, [submitError]);

    useEffect(() => {
        let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
        p.then(res => {
            if (res?.customerContactDetails) {
                setName(res.customerContactDetails.name);
                setPhoneCode(res.customerContactDetails.phoneCode);
                setPhoneNo(res.customerContactDetails.phoneNo);
                setEmail(res.customerContactDetails.email);
            }
        })
    }, []);

    return (
        <div id={"contact-details"} style={{ marginTop: "24px" }} >
            <Typography children={"Data Pemesan (Untuk E - Ticket)"} className={"inputSectionLabels"} />

            {/* FILL THE CONTACT DETAILS */}
            <Accordion expanded={openContactDetails} onChange={() => setOpenContactDetails(!openContactDetails)} id={"dataPemesan-dropdown"} style={{ marginTop: "16px" }} className={"dropShadow"} >
                <AccordionSummary >
                    {(name === "" || phoneCode === "" || phoneNo === "" || email === "") || (openContactDetails) ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                            <Typography className={"inputSecondarySectionLabels"} >
                                Isi Data Pemesan
                                <span style={{ color: "red", fontSize: "bold" }} >*</span>
                            </Typography>
                            <Typography children={"+ Isi Data"} style={{ fontWeight: "bold", fontSize: "14px", color: "#1A1A1A", display: openContactDetails ? "none" : "" }} />
                        </div>
                        :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <div style={{width:"100%"}}>
                                <Typography children={name} style={{ fontWeight: "600", fontSize: "12px", color: "#1A1A1A"/*, textTransform:"capitalize"*/ }} />
                                <div className={classes.emailWrapper}>
                                  <Typography children={email} style={{ fontSize: "12px", color: "#666"}} />
                                </div>
                                <Typography children={`+${phoneCode} ${phoneNo}`} style={{ fontSize: "12px", color: "#666" }} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <EditRoundedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                                <Typography children={"Ubah"} style={{ fontWeight: "bold", fontSize: "14px", color: "#1A1A1A" }} />
                            </div>
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: "0" }} >
                    <Divider style={{ marginBottom: "16px" }} />
                    <Typography children={"Nama Lengkap"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }} />

                    {/* CONTACT PERSON NAME */}
                    <TextField id={"namaLengkap-value"} fullWidth autoComplete={"off"}
                        value={name}
                        error={error && name === ""}
                        onChange={(e) => {
                            if (e.target.value.length <= 25 && /^[a-zA-Z ]*$/.test(e.target.value))
                                setName(e.target.value)
                        }
                        }
                        FormHelperTextProps={{ style: { margin: "0", color: "#c21616", marginBottom: "24px", display: error ? "" : "none" } }}
                        helperText={(error && name === "") ? "Mohon untuk tidak memasukkan tanda baca" : ""}
                        InputProps={{ style: { height: "40px", borderRadius: "8px", margin: "8px 0" } }}
                    />
                    <Typography children={"Sesuai KTP/paspor/SIM (tanpa tanda baca atau gelar)"} style={{ fontSize: "11px", color: "#979797", marginBottom: "24px", display: (error && name === "") ? "none" : "" }} />

                    {/* PHONE INFO */}
                    <Grid container spacing={2} >
                        {/* PHONE CODE */}
                        <Grid item xs={4} >
                            <PhoneCode phoneCode={phoneCode} error={error} handleChange={setPhoneCode} />
                        </Grid>
                        {/* PHONE NUMBER */}
                        <Grid item xs={8} >
                            <PhoneNumber phoneNo={phoneNo} error={error} handleChange={setPhoneNo} />
                        </Grid>
                    </Grid>

                    {/* ----EMAIL INFO---- */}
                    <EmailInfo email={email} emailRegex={emailRegex} error={error} handleChange={setEmail} />
                    <Button children={"Simpan"} className={"submitBlackButton"} fullWidth onClick={() => handleSubmit()} />
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const PhoneCode = ({ phoneCode, error, handleChange }) => {
    const classes = useStyle();
    return (
        <>
            <Typography children={"Kode Negara"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }} />

            <TextField id={"kodeNegara-value"} fullWidth autoComplete={"off"} className={classes.telInput}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                type={"number"}
                InputProps={{
                    style: { height: "40px", borderRadius: "8px", margin: "8px 0" },
                    startAdornment: <AddIcon fontSize={"small"} />
                }}
                FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                error={error && (phoneCode === "" || phoneCode < 0)}
                helperText={((phoneCode === "" || phoneCode < 0) && error) ? "Pilih Kode Negara" : ""}
                value={phoneCode}
                onChange={(e) => { if (e.target.value.length <= 5) handleChange(e.target.value) }}
            />
        </>
    )
}

const PhoneNumber = ({ phoneNo, error, handleChange }) => {
    const classes = useStyle();
    return (
        <>
            <Typography children={"No. Handphone"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }} />

            <TextField autoComplete={"off"} fullWidth className={classes.telInput} id={"handphone-value"}
                error={(error && (phoneNo < 0 || phoneNo === "" || phoneNo.length < 10)
                    // (phoneNo.length!===10 && error)||
                )}
                value={phoneNo}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                type={"number"}
                onChange={(e) => { if (e.target.value.length <= 15) handleChange(e.target.value) }}
                FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                helperText={((phoneNo === "" || phoneNo < 0 || phoneNo.length < 10)/*||(error && phoneNo.length!===10)*/ && error) ? "No. Handphone tidak terdaftar" : ""}
                InputProps={{ style: { height: "40px", borderRadius: "8px", margin: "8px 0" } }}
            />
        </>
    )
}

const EmailInfo = ({ email, emailRegex, error, handleChange }) => {

    return (
        <>
            <Typography children={"Email"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151", marginTop: "12px" }} />
            <TextField autoComplete={"off"} fullWidth id={"email"}
                error={(error && email === "") || (error && !emailRegex.test(email))}
                value={email}
                onChange={(e) => handleChange(e.target.value)}
                InputProps={{ style: { height: "40px", borderRadius: "8px", margin: "8px 0" } }}
                FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                helperText={(email === "" && error) || (error && !emailRegex.test(email)) ? "Email tidak terdaftar" : ""}
            />
            <Typography children={"Contoh: email@example.com"} style={{ fontSize: "11px", color: "#979797", display: (email === "" && error) || (error && !emailRegex.test(email)) ? "none" : "" }} />
        </>
    )
}

PhoneCode.propTypes = {
    phoneCode: PropTypes.string,
    error: PropTypes.bool,
    handleChange: PropTypes.func
  };
  
  PhoneNumber.propTypes = {
    phoneNo: PropTypes.string,
    error: PropTypes.bool,
    handleChange: PropTypes.func
  };
  
  EmailInfo.propTypes = {
    email: PropTypes.string,
    emailRegex: PropTypes.string,
    error: PropTypes.bool,
    handleChange: PropTypes.func
  };
  
      
  ContactDetails.propTypes = {
      submitError: PropTypes.bool,
    };

export default ContactDetails

const useStyle = makeStyles({
    telInput: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    emailWrapper:{
        width:'95%',
        wordBreak:'break-word',
        margin:'5px 0'
    }
})


{/*
    LIST OF VALID EMAIL's for regex => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    email@example.com
    firstname.lastname@example.com
    email@subdomain.example.com
    firstname+lastname@example.com
    email@123.123.123.123
    email@[123.123.123.123]
    "email"@example.com
    1234567890@example.com
    email@example-one.com
    _______@example.com
    email@example.name
    email@example.museum
    email@example.co.jp
    firstname-lastname@example.com

*/}