/* eslint-disable */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/id'
moment.locale('id');
import { id as indonesian } from 'date-fns/locale'; // this is localization for Indonesian Language......
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, Paper, Radio, Slide, styled, TextField, Typography } from '@mui/material';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseIcon from '@mui/icons-material/Close';
import { blueGrey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import PropTypes from "prop-types";

const PassengerInfo = ({ ticiketInfo: { adultPassengers, childPassengers, totalPassengers, departdate }, submitError }) => {

    const classes = useStyle();
    const [openUserID, setOpenUserID] = useState(false);

    const [radioButton, setRadioButton] = useState('KTP'); // default button set to KTP
    const [userID, submitUserID] = useState(''); // set user id on click of submit button in Slider
    const [userIndex, setUserIndex] = useState(''); // gets the user index from list array on click on Jenis ID

    const [list, setList] = useState([]);

    useEffect(() => {
        if (!totalPassengers) return;

        let temp = [];
        const KAI = JSON.parse(localStorage.getItem("KAI"));

        if (KAI?.passengerList?.length) {

            temp = [...KAI.passengerList]
            for (const item of temp) {
                item.expand = false;
            }
            KAI.passengerList = temp;
            localStorage.setItem("KAI", JSON.stringify(KAI));
        }
        else {
            let j = adultPassengers, k = childPassengers;
            for (let i = 0; i < totalPassengers;) {
                if (j > 0) {
                    temp[i] = {
                        passenger: "adult",
                        passengerID: "KTP",
                        name: "",
                        numberID: "",
                        id: i,
                        expand: false
                    };
                    j--;
                    i++;
                }
                if (!(j) && k > 0) {
                    temp[i] = {
                        passenger: "child",
                        passengerID: "KTP",
                        name: "",
                        numberID: "",
                        id: i,
                        expand: false
                    };
                    k--;
                    i++;
                }
            }
            KAI.passengerList = [...temp];
            localStorage.setItem("KAI", JSON.stringify(KAI));
        }
        setList(temp);
    }, [adultPassengers, childPassengers, totalPassengers]);

    const [passengerName, setPassengerName] = useState("");
    const [passengerNumberID, setPassengerNumberID] = useState("");
    const [childBirthDate, setChildBirthDate] = useState("");
    const [error, setError] = useState(false);
    const handleAccordion = (i) => {
        setUserIndex(i);
        let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
        p.then((res) => {
            let KAI = res;
            let list = [...KAI.passengerList];

            for (let index = 0; index < list.length; index++) {
                if (index == i) {
                    list[index].expand = !(list[index].expand);
                    setPassengerName(list[i].name);
                    setPassengerNumberID(list[i].numberID);
                    setRadioButton(list[i].passengerID);
                    if (list[i].passenger !== "adult") {
                        setChildBirthDate(list[i]?.childBirthDate ? list[i]?.childBirthDate : "");
                    }
                }
                else list[index].expand = false;
            }
            setError(false)
            KAI.passengerList = [...list];
            localStorage.setItem("KAI", JSON.stringify(KAI));
            setList(list)
        })
    }

    // after clicking Submit button.
    const handleSubmitPassengerInfo = (passenger) => {
        let expand;
        let ageLimit = moment(departdate, "YYYY-MM-DD").diff(moment(childBirthDate).subtract(1, "days"), 'years');
        if (passengerName != "" &&
            (
                passengerNumberID != "" &&
                (
                    (radioButton == "KTP" && passengerNumberID > 0 && passengerNumberID.length == 16)
                    ||
                    (radioButton == "Paspor" && passengerNumberID.length == 8)
                )
            ) &&
            radioButton != ""
            && ((passenger == "adult") || (passenger == "child" && ageLimit < 3))
        ) {
            setError(false);
            expand = false;
        }
        else {
            setError(true);
            expand = true;
        }

        let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
        p.then((res) => {
            let KAI = res;
            let list = [...KAI.passengerList]
            list[userIndex].expand = expand;
            list[userIndex].name = passengerName;
            list[userIndex].numberID = passengerNumberID;
            list[userIndex].childBirthDate = childBirthDate;
            setList(list);
            KAI.passengerList = [...list];
            localStorage.setItem("KAI", JSON.stringify(KAI));
        })

    }

    const handlePassengerNameOnChange = (e) => {
        if (e.target.value.length <= 25 && /^[a-zA-Z ]*$/.test(e.target.value))
            setPassengerName(e.target.value)
    }

    const handlePassengerIDOnChange = (e, passengerID) => {
        if (e.target.value.length <= 16 && passengerID == "KTP")
            setPassengerNumberID(e.target.value);
        if (e.target.value.length <= 8 && passengerID == "Paspor")
            setPassengerNumberID(e.target.value);
    }

    const validatePassengerIDLength = (passengerID) => {
        return (passengerNumberID == "" || passengerNumberID < 0) ||
            (
                (passengerID == "KTP" && passengerNumberID.length != 16) ||
                (passengerID == "Paspor" && passengerNumberID.length != 8)
            )
    }
    const validatePassengerName = () => {
        return (passengerName == "" || passengerName.length < 3)
    }
    useEffect(() => {

        if (!submitError) return

        const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
        p.then(res => {
            const KAI = res;
            if (!KAI?.passengerList?.length) return;

            let passengerList = KAI.passengerList;

            for (let i = 0; i < passengerList.length; i++) {
                const ktpErrorCheck = passengerList[i].passengerID === "KTP" && passengerList[i].numberID.length !== 16;
                const passportErrorCheck = passengerList[i].passengerID === "Paspor" && passengerList[i].numberID !== 8;

                const childDobError = (passengerList[i].childBirthDate == "" || moment(departdate, "YYYY-MM-DD").diff(moment(passengerList[i].childBirthDate).subtract(1, "days"), 'years') >= 3)
                const childError = passengerList[i].passenger == "child" && childDobError
                const isPassengerFieldsEmpty = passengerList[i].name == "" || passengerList[i].passengerID == "" || passengerList[i].numberID == ""

                const passengerErrorVerification = (
                    isPassengerFieldsEmpty || ((ktpErrorCheck) || (passportErrorCheck)) || childError
                )
                if (passengerErrorVerification) {
                    passengerList[i].expand = true;
                    setError(true);
                    setPassengerName(passengerList[i].name);
                    setPassengerNumberID(passengerList[i].numberID);
                    setUserIndex(i);
                    break;
                }
                else {
                    passengerList[i].expand = false;
                }
            }
            setList(passengerList);
            KAI.passengerList = [...passengerList];
            localStorage.setItem("KAI", JSON.stringify(KAI))

        })

    }, [submitError]);

    useEffect(() => {
        if (userID && list.length) {
            let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
            p.then(res => {
                let KAI = res;
                KAI.passengerList[userIndex].passengerID = radioButton;
                KAI.passengerList[userIndex].numberID = "";
                setList(KAI.passengerList);
                localStorage.setItem("KAI", JSON.stringify(KAI));

                // we use common radio component for selecting ID type so keeping it empty after setting it
                // so we dont see same ID for other USERS
                submitUserID("");
                // console.log("changed userID");
                // clear the ID number on change of ID type
                setPassengerNumberID("");
            })
        }
    }, [userID]);

    return (
        <div id="slidercomponent">
            <div style={{ marginTop: "24px" }}>
                <Typography children={"Data Penumpang"} className={"inputSectionLabels"} />
            </div>
            {/* passenger => (adult/child) | passengerID => (KTP/Paspor) */}
            {list.map(({ passenger, passengerID, name, numberID, id, expand }, i) => {
                const passengerNameCheck = validatePassengerName()
                const passengerIdLengthCheck = validatePassengerIDLength(passengerID)
                const passengerIdError = (passengerID == "Paspor" ? "Nomor ID harus 8 digit." : "Nomor ID harus 16 digit.")
                return (
                    <div key={`passenger-info-container-${passenger}-${id}`} style={{ marginTop: "16px" }}>
                        <Accordion id={`${passenger}-passengerInfo-${i}`} expanded={expand} onChange={() => handleAccordion(i)} className={"dropShadow"} /*style={{background: openUserID ? "transparent" : ""}}*/ >
                            <PassengerSummary passenger={passenger} passengerID={passengerID} name={name}
                                numberID={numberID} id={id} expand={expand} userIndex={userIndex} i={i}
                                adultPassengers={adultPassengers} />
                            <AccordionDetails style={{ paddingTop: "0" }}>
                                <Divider />
                                {/* INPUT PASSENGER NAME */}
                                <div style={{ marginTop: "16px" }}>
                                    <Typography children={"Nama Lengkap"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }} />
                                    <TextField id={`namaLengkapPassenger-value-${i}`} fullWidth
                                        InputProps={{ style: { height: "40px", borderRadius: "8px", margin: "8px 0" } }}
                                        value={passengerName} autoComplete={"off"}
                                        onChange={(e) => handlePassengerNameOnChange(e)}
                                        error={error && passengerNameCheck}
                                        FormHelperTextProps={{ style: { margin: "0", color: "#c21616" } }}
                                        helperText={error && passengerNameCheck ? "Mohon masukkan nama lengkap, tanpa tanda baca." : ""}
                                    />
                                </div>

                                {/* SELECT PASSENGER ID */}
                                <div style={{ marginTop: "16px" }}>
                                    <Typography children={"Jenis ID"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }} />

                                    <div id={`selectPassengerID-${i}`} onClick={() => setOpenUserID(!openUserID)}
                                        style={{ margin: "8px 0", border: error && passengerID == "" ? "1px solid #c21616" : "1px solid #DDD", borderRadius: "8px", fontSize: "12px", color: "#1A1A1A", padding: "12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography children={passengerID} style={{ fontSize: "14px" }} />
                                        <KeyboardArrowDownIcon style={{ fontSize: "16px" }} />
                                    </div>
                                    <span style={{ display: error && passengerID == "" ? "" : "none", fontWeight: "500", color: "#c21616", fontSize: "12px" }}>Mohon pilih jenis ID</span>
                                </div>

                                {/* INPUT PASSENGER ID VALUE */}
                                <div style={{ marginTop: "16px" }}>
                                    <Typography children={"Nomor ID"} style={{ fontWeight: "bold", fontSize: "12px", color: "#515151" }} />
                                    <TextField id={`nomorID-value-${i}`} fullWidth className={classes.telInput} autoComplete={"off"}
                                        InputProps={{ style: { height: "40px", borderRadius: "8px", margin: "8px 0" } }}
                                        value={passengerNumberID}
                                        type={passengerID == "Paspor" ? "text" : "number"}
                                        inputProps={{
                                            inputMode: passengerID == "Paspor" ? '' : 'numeric',
                                            pattern: passengerID == "Paspor" ? '' : '[0-9]*'
                                        }}
                                        onChange={(e) => handlePassengerIDOnChange(e, passengerID)}
                                        error={
                                            error && passengerIdLengthCheck
                                        }
                                        FormHelperTextProps={{ style: { margin: "0", color: "#c21616" } }}
                                        helperText={
                                            error && passengerIdLengthCheck ? passengerIdError : ""
                                        }
                                    />
                                    <Typography children={"Untuk penumpang di bawah 17 tahun, mohon gunakan NIK yang terdapat di Kartu Keluarga"}
                                        style={{
                                            fontSize: "11px", color: "#979797",
                                            display: error && passengerIdLengthCheck ? "none" : ""
                                        }} />
                                </div>

                                {/* SELECT DOB IF CHILD */}
                                {
                                    passenger !== "adult" && <ChildDateOfBirth childBirthDate={childBirthDate} departDate={departdate} error={error} handleChange={setChildBirthDate} />

                                }

                                <Button id={`simpanPassenger-${i}`} fullWidth children={"Simpan"} variant={"contained"} className={"submitBlackButton"}
                                    onClick={() => handleSubmitPassengerInfo(passenger)}
                                />
                            </AccordionDetails>

                        </Accordion>
                    </div>
                )
            }
            )}

            {/* CHANGE ID (KTP/Paspor) POPUP */}
            <SelectUserID radioButton={radioButton} setRadioButton={setRadioButton} setOpenUserID={setOpenUserID} openUserID={openUserID} submitUserID={submitUserID} />

        </div>
    )
}

export default PassengerInfo;

const ChildDateOfBirth = ({ childBirthDate, departDate, error, handleChange }) => {
    const classes = useStyle();

    return (
        <div>
            <div style={{ marginTop: "16px" }}>
                <InputLabel children={"Tanggal Lahir"} id="TanggalLahir-label" />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={indonesian} >
                    <MobileDatePicker InputProps={{ style: { borderRadius: "8px" } }} disableFuture
                        DialogProps={{ className: classes.selectDepartureDate }}
                        value={childBirthDate} cancelText={""}
                        onChange={(date) => handleChange(date)}
                        renderInput={(params) => {
                            params.inputProps.value = childBirthDate && moment(params.inputProps.value, "DD/MM/YYYY").format("ddd, DD MMM YYYY");
                            let dateValidation = moment(departDate, "YYYY-MM-DD").diff(moment(childBirthDate).subtract(1, "days"), 'years') >= 3 ? false : true;
                            return (
                                <TextField fullWidth className={classes.departureDateTextField} {...params}
                                    error={(error && !dateValidation) || (error && !childBirthDate)}
                                    helperText={((error && !dateValidation) || (error && !childBirthDate)) && "Umur Bayi Tidak Sesuai"}
                                    FormHelperTextProps={{ style: { margin: "5px 0px 0px", color: "#c21616" } }}
                                />
                            )
                        }}
                    />
                </LocalizationProvider>
            </div>

            <div style={{ marginTop: "16px" }}>
                <Typography children={"Penumpang bayi tidak mendapat kursi sendiri"} style={{ border: "1px solid #DDD", borderRadius: "8px", fontSize: "12px", color: "#666", background: "#F2F2F2", padding: "12px", textAlign: "center" }} />
            </div>
        </div>
    )
}
const PassengerSummary = ({ passenger, passengerID, name, numberID, id, expand, userIndex, i, adultPassengers }) => {
    return (
        <AccordionSummary  >
            {(expand && userIndex == i) || (name == "" || numberID == "") ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                    <div style={{ display: "flex", alignItems: "center" }} >
                        <AccountCircleIcon style={{ marginRight: "10px", color: "D8D8D8" }} />
                        <Typography children={passenger === "adult" ? `Dewasa ${i + 1}` : `Bayi ${(i + 1) - adultPassengers}`} className={"inputSecondarySectionLabels"} />
                        <span style={{ color: "red", fontSize: "bold" }} >*</span>
                    </div>
                    {(expand && userIndex == i) ?
                        <KeyboardArrowUpRoundedIcon style={{ fontSize: "16px" }} />
                        :
                        <Typography children={"+ Isi Data"} style={{ fontWeight: "bold", fontSize: "14px", color: "#1A1A1A" }} />
                    }
                </div>
                :
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <div >
                        <Typography children={name} style={{ fontWeight: "bold", fontSize: "12px", color: "#1A1A1A"/*, textTransform:"capitalize"*/ }} />
                        <Typography children={`${passengerID} - ${numberID}`} style={{ fontSize: "12px", color: "#666" }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }} >
                        <EditRoundedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                        <Typography children={"Ubah"} style={{ fontWeight: "bold", fontSize: "14px", color: "#1A1A1A" }} />
                    </div>
                </div>
            }
        </AccordionSummary>
    )
}

const SelectUserID = ({ radioButton, setRadioButton, openUserID, setOpenUserID, submitUserID }) => (
    <div style={{ position: "fixed", top: "0", bottom: "0", right: "0", left: "0", zIndex: "1", background: "#00000042", display: openUserID ? "" : "none" }} >
        <Slide direction={"up"} in={openUserID} className={""} style={{ position: "absolute", bottom: "0", right: "0", left: "0", maxWidth: "640px", margin: "auto", zIndex: "1", borderRadius: "16px 16px 0 0" }} id='slider-container-ID' >
            <Paper id="slider-paper-ID">
                <div style={{ padding: "16px" }} >
                    <div style={{ margin: "auto", width: "48px", height: "4px", background: "#DDD", borderRadius: "8px" }} />

                    {/* SELECT ID HEADER */}
                    <div className={"selectStationSliderHeader"}>
                        <Typography children={"Jenis ID"} className='headerText' id="headerTextSlider-ID" />
                        <IconButton onClick={() => setOpenUserID(!openUserID)}> <CloseIcon style={{ color: "#1A1A1A" }} /> </IconButton>
                    </div>

                    {/* RADIO BUTTONS */}
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Radio id="KTP"
                                sx={{
                                    color: blueGrey[900],
                                    '&.Mui-checked': {
                                        color: '#00BFB2'
                                    }
                                }}
                                checked={radioButton === 'KTP'}
                                onChange={(e) => setRadioButton(e.target.value)}
                                value="KTP" />
                            <Typography children={"KTP"} style={{ fontWeight: "500", fontSize: "14px", color: "#1A1A1A", marginLeft: "5px" }} />
                        </div>

                        <Divider />

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Radio id="Paspor"
                                sx={{
                                    color: blueGrey[900],
                                    '&.Mui-checked': {
                                        color: '#00BFB2'
                                    }
                                }}
                                checked={radioButton === 'Paspor'}
                                onChange={(e) => setRadioButton(e.target.value)}
                                value="Paspor" />
                            <Typography children={"Paspor"} style={{ fontWeight: "500", fontSize: "14px", color: "#1A1A1A", marginLeft: "5px" }} />
                        </div>
                    </div>

                    <Button id="tutup-button" fullWidth className={"submitBlackButton"} children={"Tutup"}
                        onClick={() => {
                            submitUserID(radioButton);
                            setOpenUserID(!openUserID);
                        }}
                    />
                </div>
            </Paper>
        </Slide>
    </div>
)

ChildDateOfBirth.propTypes = {
    childBirthDate: PropTypes.bool,
    departDate: PropTypes.date,
    error: PropTypes.bool,
    handleChange: PropTypes.func,
};

PassengerInfo.propTypes = {
    ticiketInfo: PropTypes.object,
    submitError: PropTypes.bool,
  };
  
  SelectUserID.propTypes = {
    radioButton: PropTypes.string,
    setRadioButton: PropTypes.func,
    openUserID: PropTypes.bool,
    setOpenUserID: PropTypes.func,
    setUserId: PropTypes.func,
  };

const useStyle = makeStyles({
    telInput: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },

    selectDepartureDate: {
        // Hide Cancel Button
        '& .MuiDialogActions-root>:first-of-type': {
            display: 'none'
        },
        // giv a line after the date displayed at top in Date Picker
        '& .PrivatePickersToolbar-root': {
            borderBottom: "1px solid #DDD !important"
        },
        // removed the edit button which changed the view of the date picker
        '& .MuiPickersToolbar-penIconButton': {
            display: "none",
        },
        // change color and background of the CANCEL and OK button
        '& .MuiButton-root': {
            background: "#00BFB2",
            color: "#FFF",
            '&:hover': {
                background: "#00BFB2",
            }
        },
        // background color applied to date when selected and also when seledted and hover on it
        '& .Mui-selected': {
            backgroundColor: "#00BFB2 !important",
            '&:hover': {
                backgroundColor: "#00BFB2bf !important"
            }
        },
        '& .MuiPickersDay-today': {
            borderColor: '#00BFB2'
        }
    },
    departureDateTextField: {
        // style APPLIED to TEXTFIELD 
        '& .MuiOutlinedInput-input': {
            padding: "10px 14px !important",
            fontSize: "14px !important",
        }
    }
})


const InputLabel = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#515151',
    marginBottom: '8px',
    marginTop: '24px',
})